import React from 'react';
import Linkify from 'react-linkify';

const componentDecorator = (href: any, text: any, key: any) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const Linker = ({ children }: any) => (
  <Linkify componentDecorator={componentDecorator}>{children}</Linkify>
);

export default Linker;
