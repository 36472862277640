import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Logo from '../../../assets/logo.png';
import config from '../../../config';

const Container = styled.div<{ position: string }>`
  position: ${({ position }) => position};
  bottom: 0;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled.img`
  max-width: 100px;
  object-fit: contain;
  margin: 0px 16px;
`;

const AppFooter = ({ position = 'absolute' }: { position?: string }) => {
  const { t } = useTranslation();

  return (
    <Container position={position}>
      <span>{t('poweredBy')}</span>
      <LogoContainer src="https://newprint-blue.de/wp-content/uploads/2018/07/newprint_logo.png" />
      <span>{config.COMPANY_NAME}</span>
    </Container>
  );
};

export default AppFooter;
