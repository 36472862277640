import React from 'react';
import { Select, Typography } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { CompanyType } from '../../../store/types';

const { Title } = Typography;
const { Option } = Select;

const CompanySelect = ({
  onSelect,
  options,
}: {
  onSelect: (value: SelectValue) => void;
  options: CompanyType[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>Projekt:</Title>
      <Select
        placeholder={t('selectProject')}
        style={{ width: 280, marginBottom: 16, borderRadius: 8 }}
        onChange={onSelect}
      >
        {options.map(({ companyId, name }) => (
          <Option key={companyId} value={companyId}>
            {name}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default CompanySelect;
