import { ObjectFieldTemplateProps } from '@rjsf/core';
import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Content = styled.div<{ hideObjectBorder: boolean, noTitle: boolean }>`
  border: ${({ hideObjectBorder }) => (hideObjectBorder ? 'none' : '1px solid #cacaca;')};
  border-radius: 8px;
  padding: ${({noTitle}) => noTitle ? '0px 12px' : '12px'};
  margin-bottom: ${({noTitle}) => noTitle ? '0px' : '8px'};

  &:hover {
    border: ${({ hideObjectBorder, theme }) =>
      hideObjectBorder ? 'none' : `1px solid ${theme.primary};`};
  }
`;

const Container = styled.div`
  
`;

const ObjectField = ({
  title,
  description,
  properties,
  hideObjectBorder = false,
}: ObjectFieldTemplateProps & { hideObjectBorder?: boolean }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {description}
      <Content 
        hideObjectBorder={hideObjectBorder}
        noTitle={hideObjectBorder && title === ""} //Need to recheck on all field objects without label
      >
        {properties.map((element) => {
          return (
            <div key={element.name} className="property-wrapper">
              {element.content}
            </div>
          );
        })}
      </Content>
    </Container>
  );
};

export default ObjectField;
