import React, { useRef, useCallback } from 'react';
import { AjvError, ObjectFieldTemplateProps, withTheme } from '@rjsf/core';
import styled from 'styled-components';
import { Button } from 'antd';
import { debounce } from 'lodash';

// @ts-ignore
import { Theme as AntDTheme } from '@rjsf/antd';
import { useTranslation } from 'react-i18next';
import errorMessages from './errorMessages';
import ObjectField from './objectField.component';

import './form.theme.css';
import ArrayField from './arrayField.component';
import { HtmlLinkCheckboxWidget } from './custom/htmlLinkCheckboxWidget';
import { Version1FormWidget } from './custom/version1FormWidget';
import { Version2FormWidget } from './custom/version2FormWidget';
import { RecaptchaWidget } from './custom/recaptchaWidget';

import { notificationType, showNotification } from '../../../utils/notification';

const Form = withTheme(AntDTheme);

interface SchemaFormProps {
  schema: any;
  uiSchema?: any;
  onSubmit: (formData: any) => void;
  submitText?: string;
  initialData?: any;
  CustomSubmitBtn?: typeof Button;
  hideObjectBorder?: boolean;
  liveValidate?: boolean;
  hideNotifError?: boolean;
}

const ignoreValidationNames = ['const', 'enum', 'oneOf'];

const SubmitButton = styled(Button)`
  display: block;
  margin: 24px auto;
  width: 250px;
  color: #fff;
  height: 50px;
  font-size: 16px;
`;

const SchemaForm: React.FC<SchemaFormProps> = ({
  schema,
  onSubmit,
  uiSchema = {},
  submitText,
  initialData,
  CustomSubmitBtn,
  hideObjectBorder = false,
  liveValidate = false,
  hideNotifError = false
}) => {
  const { t } = useTranslation();
  const submitButton = useRef<HTMLButtonElement>(null);

  if(!submitText){
    submitText = t('form.submit')
  }

  const submitForm = useCallback(
    debounce(({ formData }: any) => {
      onSubmit(formData);
    }, 300),
    [onSubmit],
  );

  const handleError = useCallback((err: any) => {
    if(!hideNotifError){
      showNotification(notificationType.ERROR, t('notif.formFieldErrors'));
    }
  }, [t]);

  const transformErrors = (errors: AjvError[]) => {
    return errors
      .filter((item) => !ignoreValidationNames.includes(item.name))
      .map((error) => ({
        ...error,
        message: errorMessages[error.name] || t('form.valueIsNotValid'),
      }));
  };

  const objectSchemaField = useCallback((prop: ObjectFieldTemplateProps) => {
    return ObjectField({ ...prop, hideObjectBorder });
  },[])

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={initialData}
      showErrorList={false}
      onSubmit={submitForm}
      transformErrors={transformErrors}
      ObjectFieldTemplate={objectSchemaField}
      ArrayFieldTemplate={ArrayField}
      noHtml5Validate
      onError={handleError}
      liveValidate={liveValidate}
      fields={{
        HtmlLinkCheckboxWidget,
        Version1FormWidget,
        Version2FormWidget,
        RecaptchaWidget,
      }}
    >

      {CustomSubmitBtn ? (
        <CustomSubmitBtn htmlType="submit" type="primary" ref={submitButton}>
          {submitText}
        </CustomSubmitBtn>
      ) : (
        <SubmitButton htmlType="submit" type="primary" ref={submitButton}>
          {submitText}
        </SubmitButton>
      )}
    </Form>
  );
};

export default SchemaForm;
