import React, { Redirect, Route } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import appRoutes from '../app.routes';

const PrivateRoute = ({
  children,
  exact,
  path,
}: {
  children: any;
  exact?: boolean;
  path: string;
}) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => {
        if (isAuthenticated) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: appRoutes.LOGIN,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
