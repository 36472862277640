export default {
  default: "^[\.a-zA-Z0-9,'# ]*$",
  alphanumeric: "^[\.a-zA-Z0-9]*$",
  numeric: "^[\.0-9]*$",
  alphabets: "^[\.a-zA-Z. ]*$",
  email: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$',
  slug: '^([a-zA-Z][a-zA-Z0-9]*)(-[a-zA-Z0-9]+)*$',
  telephone: '^([+0-9]*)(-[0-9]+)*$',
  names: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",
  commaNumeric: "[0-9]+([\.,][0-9]+)?"
};
