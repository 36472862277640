import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import appRoutes from '../../app/app.routes';
import { notificationType, showNotification } from '../../utils/notification';
import { OrderType } from '../types';
import CryptoJS from "crypto-js";

const initialState: {
  list: OrderType[];
  pages: number;
  selectedCompany: number | undefined;
  selectedForm: number | undefined;
} = {
  list: [],
  pages: 0,
  selectedCompany: undefined,
  selectedForm: undefined,
};

const apiEndpoint = '/orders';

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetList: () => {
      return initialState;
    },
    setList: (state, action: PayloadAction<{ list: OrderType[]; pages: number }>) => {
      return {
        ...state,
        list: action.payload.list,
        pages: action.payload.pages,
        selectedForm:
          action.payload.list.length > 0 ? action.payload.list[0].formId : undefined,
      };
    },
    clearSelection: () => {
      return {
        ...initialState,
      };
    },
  },
});

const submitPublicOrderForm = createAsyncThunk(
  '/submitPublicOrderForm',
  async (payload: { orderData: Partial<OrderType>; urlKey: string }) => {
    try {
      const response = await axios.post(apiEndpoint, payload.orderData);
      const order = response.data.data;

      if (order) {
        //encrpyt formKey and orderId
        const urlParams = {
          formKey: payload.urlKey,
          orderId: order.orderId
        };
        const encrpytKey = encodeURIComponent(CryptoJS.Rabbit.encrypt(JSON.stringify(urlParams), 'test').toString());

        if(order.isPreorder){
          window.location.href = `${appRoutes.PAGE_ORDER_SUCCESS_PREORDER}?id=${encrpytKey}`;
        } else {
          window.location.href = `${appRoutes.PAGE_ORDER_SUCCESS}?id=${encrpytKey}`;
        }
      } else {
        // Error
        showNotification(notificationType.ERROR, response.data.message);
      }
    } catch {
      // Error
      showNotification(notificationType.ERROR);
    }
  },
);

const getCompanyFormOrders = createAsyncThunk(
  '/getCompanyFormOrders',
  async (
    payload: {
      companyId: number;
      sortKey: string;
      page: number;
      direction: string;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.get(
        `${apiEndpoint}/company/${payload.companyId}?page=${payload.page}&sortKey=${payload.sortKey}&direction=${payload.direction}`,
      );
      const orders = response.data.data;

      if (orders) {
        thunkAPI.dispatch(orderSliceActions.setList(orders));
      } else {
        thunkAPI.dispatch(orderSliceActions.resetList());
        showNotification(notificationType.WARNING, response.data.message);
      }
    } catch {
      showNotification(notificationType.ERROR);
    }
  },
);

const downloadFormOrdersSummary = createAsyncThunk(
  '/downloadFormOrdersSummary',
  async (payload: { formId: number }) => {
    try {
      axios({
        method: 'POST',
        url: `${apiEndpoint}/summary/download`,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          formId: payload.formId,
        },
      })
        .then((res) => {
          fileDownload(res.data, `Order Summary_${moment().format('DD_MM_YYYY')}.xlsx`);
        })
        .catch(() => {
          showNotification(notificationType.ERROR);
        });
    } catch {
      showNotification(notificationType.ERROR);
    }
  },
);

const downloadOrderReceipt = createAsyncThunk(
  '/downloadOrderReceipt',
  async (payload: { orderId: number }) => {
    try {
      axios({
        method: 'POST',
        url: `${apiEndpoint}/receipt/download`,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          orderId: payload.orderId,
        },
      })
        .then((res) => {
          fileDownload(res.data, `Bestellübersicht_${payload.orderId}_${moment().format('DD_MM_YYYY')}.pdf`);
        })
        .catch(() => {
          showNotification(notificationType.ERROR);
        });
    } catch {
      showNotification(notificationType.ERROR);
    }
  },
);

export const orderSliceActions = {
  ...orderSlice.actions,
  submitPublicOrderForm,
  getCompanyFormOrders,
  downloadFormOrdersSummary,
  downloadOrderReceipt
};
