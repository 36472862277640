import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CompanyType } from '../types';

const initialState: {
  list: CompanyType[];
} = {
  list: [],
};

const apiEndpoint = '/companies';

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<CompanyType[]>) => {
      return {
        ...state,
        list: action.payload,
      };
    },
  },
});

const getCompanies = createAsyncThunk('/getCompanies', async (_, thunkAPI) => {
  const response = await axios.get(apiEndpoint);
  const list = response.data.data;

  if (list) {
    thunkAPI.dispatch(companySliceActions.setList(list));
  }
});

export const companySliceActions = {
  ...companySlice.actions,
  getCompanies,
};
