import { SelectValue } from 'antd/lib/select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Pagination, Row, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { DownloadOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import ContentBody from '../../common/contentBody/contentBody.component';
import CompanySelect from '../../common/companyProjectSelect/companyProjectSelect.component';
import { CompanyType, OrderType } from '../../../store/types';
import { orderSliceActions } from '../../../store/slices/order';
import config from '../../../config';
import { AppTheme } from '../../../themes/theme';

const { Title } = Typography;

const Details = styled.div`
  padding-left: 16px;
`;

const ProductTable = styled(Table)`
  margin: 24px 0px;
  border: 1px solid #cacaca;
  .ant-table-thead > tr > th {
    background-color: #e0e0e0;
    color: #0F0F0F;
  }
`;

const OrderTable = styled(Table)`
  border: 1px solid #cacaca;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-thead th {
    cursor: pointer;
    background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
    color: #fff;
  }
`;

const CustomPagination = styled(Pagination)`
  .ant-pagination-options-size-changer.ant-select {
    display: none;
  }
`;

const RowLabel = ({ title, data }: { title: string; data: string }) => {
  return (
    <Row style={{ marginBottom: 8 }}>
      <strong style={{ marginRight: 12 }}>{title}:</strong>
      {data}
    </Row>
  );
};

interface Props {
  companyList?: CompanyType[];
  onCompanySelect: (value: SelectValue) => void;
  selectedForm: number | undefined;
  orderList: OrderType[];
  pages: number;
  currPage: number;
  onPageChange: (value: number) => void;
  onSortChange: (key: string, label: string) => void;
  currSort: {
    key: string;
    label: string;
  };
  currDirection: string;
}

const ViewOrdersComponent = ({
  companyList = [],
  onCompanySelect,
  selectedForm,
  pages = 0,
  orderList,
  currPage,
  onPageChange,
  onSortChange,
  currSort,
  currDirection,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isSysadmin } = useAuth();

  const headerColumn = (
    column: ColumnType<Record<string, any>> | ColumnGroupType<Record<string, any>>,
  ) => ({
    onClick: () => {
      if ('dataIndex' in column) {
        onSortChange(column.dataIndex as string, column.title as string);
      }
    },
  });

  const initDownload = () => {
    if (selectedForm) {
      dispatch(
        orderSliceActions.downloadFormOrdersSummary({
          formId: selectedForm,
        }),
      );
    }
  };

  return (
    <ContentBody title={t('navigation.viewOrders')}>
      {isSysadmin && <CompanySelect options={companyList} onSelect={onCompanySelect} />}

      {selectedForm ? (
        <>
          {isSysadmin && <Divider />}
          <p>
            <strong>{t('sortedBy')}: </strong>
            {currSort.label} (
            {currDirection === 'asc' ? <ArrowUpOutlined /> : <ArrowDownOutlined />})
          </p>
          <OrderTable
            dataSource={orderList}
            rowKey="orderId"
            pagination={false}
            columns={[
              {
                title: t('orderNo'),
                dataIndex: 'orderId',
                key: 'orderId',
                onHeaderCell: headerColumn,
              },
              {
                title: t('company'),
                dataIndex: 'companyName',
                key: 'companyName',
                onHeaderCell: headerColumn,
              },
              {
                title: t('form.firstName'),
                dataIndex: 'firstName',
                key: 'firstName',
                onHeaderCell: headerColumn,
              },
              {
                title: t('form.lastName'),
                dataIndex: 'lastName',
                key: 'lastName',
                onHeaderCell: headerColumn,
              },
              {
                title: t('orderDate'),
                dataIndex: 'dateCreated',
                key: 'dateCreated',
                render: (date) => moment(date).format(config.DATE_FORMAT),
                onHeaderCell: headerColumn,
              },
              {
                title: t('remarks'),
                dataIndex: 'isPreorder',
                key: 'isPreorder',
                render: (isPreorder) =>
                  isPreorder ? (
                    <Tag color="volcano">{t('preorder')}</Tag>
                  ) : (
                    <Tag color="green">{t('none')}</Tag>
                  ),
                onHeaderCell: headerColumn,
              },
            ]}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (data) => {
                const record = data as OrderType;
                return (
                  <>
                    <Row>
                      <Col span={12}>
                        <Title level={5}>{t('form.orderData')}</Title>
                        <Details>
                          <RowLabel title={t("form.company")} data={record.companyName} />
                          <RowLabel title={t("form.firstName")} data={record.firstName} />
                          <RowLabel title={t("form.lastName")} data={record.lastName} />
                          <RowLabel title={t("form.suffix")} data={record.nameSuffix || ''} />
                          <RowLabel title={t("form.email")} data={record.email} />
                          <RowLabel
                            title={t("form.telephoneNumber")}
                            data={record.telephoneNumber}
                          />
                        </Details>
                      </Col>
                      <Col span={12}>
                        <Details>
                          <RowLabel title={t("form.street")} data={record.street} />
                          <RowLabel title={t("form.houseNumber")} data={record.houseNumber} />
                          <RowLabel title={t("form.postCode")} data={record.postCode} />
                          <RowLabel title={t("form.city")} data={record.city} />
                          <RowLabel title={t("form.country")} data={record.country} />
                        </Details>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={12}>
                        <Title level={5}>{t('form.billingAddress')}</Title>
                        <Details>
                          <RowLabel
                            title={t("company")}
                            data={record.billingCompanyName || 'NA'}
                          />
                          <RowLabel
                            title={t("form.firstName")}
                            data={record.billingFirstName || 'NA'}
                          />
                          <RowLabel
                            title={t("form.lastName")}
                            data={record.billingLastName || 'NA'}
                          />
                          <RowLabel
                            title={t("form.suffix")}
                            data={record.billingNameSuffix || 'NA'}
                          />
                          <RowLabel title={t("form.email")} data={record.billingEmail || 'NA'} />
                        </Details>
                      </Col>
                      <Col span={12}>
                        <Title style={{ visibility: 'hidden' }} level={5}>
                          .
                        </Title>
                        <Details>
                          <RowLabel title={t("form.street")} data={record.billingStreet || 'NA'} />
                          <RowLabel
                            title={t("form.houseNumber")}
                            data={record.billingHouseNumber || 'NA'}
                          />
                          <RowLabel
                            title={t("form.postCode")}
                            data={record.billingPostCode || 'NA'}
                          />
                          <RowLabel title={t("form.city")} data={record.billingCity || 'NA'} />
                          <RowLabel
                            title={t("form.country")}
                            data={record.billingCountry || 'NA'}
                          />
                        </Details>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24}>
                        <Details>
                          <RowLabel
                            title={t('form.wantToBePartInteractiveMap')}
                            data={record.isInteractiveMap ? t('Ja') : t('Nein')}
                          />
                          <RowLabel
                            title={'OBN Nr.'}
                            data={record.obn || 'NA'}
                          />
                        </Details>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24}>
                        <Title level={5}>{t('orderProducts')}</Title>
                        <Details>
                          <ProductTable
                            rowKey="orderItemId"
                            dataSource={record.OrderItem?.filter(
                              (item) => item.pieces > 0,
                            )}
                            pagination={false}
                            indentSize={100}
                            columns={[
                              {
                                title: t('type'),
                                dataIndex: 'product',
                                key: 'productName',
                                responsive: ['lg'],
                                render: (item) => item.name,
                              },
                              {
                                title: t('weightPerUnit'),
                                dataIndex: 'product',
                                key: 'weight',
                                render: (item) => item.weight,
                              },
                              {
                                title: t('pieces'),
                                dataIndex: 'pieces',
                                key: 'pieces',
                              },
                            ]}
                          />
                        </Details>
                      </Col>
                    </Row>
                  </>
                );
              },
            }}
          />
          <Row style={{ alignItems: 'center', marginTop: 24 }}>
            <Col span={18}>
              <CustomPagination
                hideOnSinglePage
                onChange={onPageChange}
                defaultPageSize={10}
                current={currPage}
                defaultCurrent={1}
                total={pages * 10}
                pageSizeOptions={[]}
              />
            </Col>
            <Col span={6} style={{ textAlign: 'end' }}>
              <Button
                style={{ height: 40 }}
                icon={<DownloadOutlined />}
                type="primary"
                onClick={initDownload}
              >
                {t("downloadSummary")}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </ContentBody>
  );
};

export default ViewOrdersComponent;
