import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAuth, setAuth } from '../../utils/auth';
import { setApiToken } from '../../utils/axios';
import { notificationType, showNotification } from '../../utils/notification';
import { AuthType } from '../types';

const initialAuth = getAuth();

const emptyState = {
  id: undefined,
  token: undefined,
  isSysadmin: undefined,
  companyId: undefined,
  username: undefined,
  company: undefined,
};

const isExistingAuth = initialAuth && initialAuth.id && initialAuth.token;
export const initialState: AuthType = isExistingAuth ? initialAuth : emptyState;

// Set Axios token
if (isExistingAuth) {
  setApiToken(initialAuth.token);
}

const apiEndpoint = '/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      return {
        ...emptyState,
      };
    },
    override: (state, action: PayloadAction<AuthType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

const login = createAsyncThunk(
  '/login',
  async (payload: { username: string; password: string }, thunkAPI) => {
    try {
      const response = await axios
        .post(`${apiEndpoint}/login`, payload)
        .then((res) => res)
        .catch((err) => {
          showNotification(notificationType.ERROR, err.response.data.msg);
        });

      if (response) {
        const { user, token } = response.data;

        if (user) {
          thunkAPI.dispatch(authSliceActions.override({ ...user, token }));
          setApiToken(token);
          setAuth({ ...user, token });
        } else {
          // Handle error
          setApiToken('');
          setAuth(null);
          showNotification(notificationType.ERROR, 'Incorrect credentials');
        }
      }
    } catch {
      showNotification(notificationType.ERROR);
    }
  },
);

export const authSliceActions = {
  ...authSlice.actions,
  login,
};
