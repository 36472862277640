import React, { useMemo } from 'react';
import { ArrayFieldTemplateProps } from '@rjsf/core';
import { Button } from 'antd';
import styled from 'styled-components';
import {
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #cacaca;
  align-items: center;
`;

const ArrayItemControls = styled.div`
  min-width: 100px;
  margin-top: 24px;
`;

const ArrayItemWrapper = styled.div<{disabled: boolean}>`
  margin-top: 0;
  margin-bottom: 16px;
  position: relative;
  display: flex;

  ${({disabled}) => disabled ? ``: `
    & input[type='text']{
      pointer-events: auto;
      background-color: #fff;
    }
    & input[type='number']{
      pointer-events: auto;
      background-color: #fff;
    }
    & textarea{
      pointer-events: auto;
      background-color: #fff;
    }
    .ant-input-number-handler-wrap { 
      display: none;
    }
  `}

  & > .field-undefined {
    padding: 0;
  }
  .ant-card {
    h4 {
      font-size: 16px;
    }
  }
  .form-group {
    margin-top: 0;
    flex: 1;
  }
`;

const ActionButton = styled(Button)`
  cursor: pointer;
  margin-left: 8px;
` as any;

const AddButton = styled(Button)`
  cursor: pointer;
` as any;

const ArrayField = ({ items, canAdd, onAddClick, title, formData }: ArrayFieldTemplateProps) => {
  const { t } = useTranslation();

  const handleAdd = useMemo(() => {
    if (items && items[0]) {
      return (items[0] as any).onAddIndexClick(0);
    }
    return onAddClick;
  }, [onAddClick, items]);

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {canAdd && (
          <AddButton icon={<PlusOutlined />} onClick={handleAdd} type="primary">
            {`${t('add')} ${title}`}
          </AddButton>
        )}
      </Header>
      {items.map((element, index) => {
        const isDisabled = "fieldDisabled" in formData[index] ? formData[index].fieldDisabled : false;

        return (
          <ArrayItemWrapper disabled={isDisabled} key={(element as any).key}>
            {element.children}
            <ArrayItemControls>
              {element.hasRemove && !isDisabled && (
                <ActionButton
                  onClick={element.onDropIndexClick(element.index)}
                  icon={<DeleteOutlined />}
                  danger
                />
              )}
            </ArrayItemControls>
          </ArrayItemWrapper>
      )})}
    </Container>
  );
};

export default ArrayField;
