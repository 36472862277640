import { SelectValue } from 'antd/lib/select';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import { RootState } from '../../../store';
import { companySliceActions } from '../../../store/slices/company';
import { formSliceActions } from '../../../store/slices/form';
import { FormProduct, FormType } from '../../../store/types';
import OrderFormsComponent from './orderForms.component';
import moment from 'moment';

const OrderFormsContainer = () => {
  const dispatch = useDispatch();
  const { isSysadmin } = useAuth();
  const { companyList, selectedForm, selectedCompany } = useSelector(
    (state: RootState) => ({
      companyList: state.company.list,
      selectedForm: state.form.selectedForm,
      selectedCompany: state.form.selectedCompany,
    }),
  );

  useEffect(() => {
    dispatch(formSliceActions.clearSelection());
  }, [dispatch]);

  useEffect(() => {
    if (isSysadmin) {
      setTimeout(() => dispatch(companySliceActions.getCompanies()), 2000);
    }
  }, [isSysadmin, dispatch]);

  const onCompanySelect = (value: SelectValue) => {
    dispatch(formSliceActions.getCompanyForm(value as number));
  };

  const onSubmit = (formData: typeof formatInputFormData) => {
    const formatSubmitFormData: Partial<FormType & {hasOrders?: boolean}> = {
      hasOrders: formData.hasOrders,
      formId: formData.formId,
      companyId: formData.companyId,
      urlKey: formData.urlKey,
      title: formData.title,
      version: formData.productListing?.version,
      enableHeading1: formData.body?.headings.heading1.enableHeading1,
      headingTitle1: formData.body?.headings.heading1.headingTitle1,
      headingText1: formData.body?.headings.heading1.headingText1,
      enableHeading2: formData.body?.headings.heading2.enableHeading2,
      headingTitle2: formData.body?.headings.heading2.headingTitle2,
      headingText2: formData.body?.headings.heading2.headingText2,
      orderStart: formData.body?.dates.orderStart,
      orderEnd: formData.body?.dates.orderEnd,
      enableEstimatedDeliveryDate:
        formData.body?.dates?.deliveryDate.enableEstimatedDeliveryDate,
      estimatedDeliveryDate: formData.body?.dates?.deliveryDate.estimatedDeliveryDate,
      enableEstimatedDeliveryText:
        formData.body?.dates?.deliveryText.enableEstimatedDeliveryText,
      estimatedDeliveryText: formData.body?.dates?.deliveryText.estimatedDeliveryText,
      enableBillingAddress: formData.additionalOptions?.enableBillingAddress,
      enableInteractiveMap: formData.additionalOptions?.enableInteractiveMap,
      enableInputObn: formData.additionalOptions?.enableInputObn,
      enableAcceptingOrders: formData.additionalOptions?.enableAcceptingOrders,
      enablePrivacyPolicy: formData.additionalOptions?.privacyPolicy.enablePrivacyPolicy,
      enablePrivacyPolicyCheckbox:
        formData.additionalOptions?.privacyPolicy.enablePrivacyPolicyCheckbox,
      privacyPolicyText: formData.additionalOptions?.privacyPolicy.privacyPolicyText,
      enableTermsAndConditionsCheckbox:
        formData.additionalOptions?.termsAndConditions.enableTermsAndConditionsCheckbox,
      enableTermsAndConditions:
        formData.additionalOptions?.termsAndConditions.enableTermsAndConditions,
      termsAndConditionsText:
        formData.additionalOptions?.termsAndConditions.termsAndConditionsText,
      FormProductSection: formData.productListing?.productSections.map((item: any) => {
        let pieces = null;

        // Only set pieces if valid and if version 1
        if (
          !Number.isNaN(item.pieces1) &&
          !Number.isNaN(item.pieces2) &&
          !Number.isNaN(item.pieces3) &&
          formData.productListing?.version === 1
        ) {
          pieces = `${item.pieces1},${item.pieces2},${item.pieces3}`;
        }

        return {
          FormProduct: item.FormProduct.map((prod: FormProduct) => ({...prod})),
          pieces,
          description: item.description,
          title: item.title,
          sectionId: item.sectionId,
          formId: item.formId,
        };
      }),
    };

    dispatch(formSliceActions.submitCompanyForm(formatSubmitFormData));
  };

  const formatInputFormData = selectedForm
    ? {
        hasOrders: selectedForm.hasOrders,
        urlKey: selectedForm.urlKey,
        title: selectedForm.title,
        companyId: selectedCompany,
        formId: selectedForm.formId,
        body: {
          headings: {
            heading1: {
              enableHeading1: selectedForm.enableHeading1,
              headingTitle1: selectedForm.headingTitle1,
              headingText1: selectedForm.headingText1,
            },
            heading2: {
              enableHeading2: selectedForm.enableHeading2,
              headingTitle2: selectedForm.headingTitle2,
              headingText2: selectedForm.headingText2,
            },
          },
          dates: {
            orderStart: selectedForm.orderStart,
            orderEnd: selectedForm.orderEnd,
            deliveryDate: {
              enableEstimatedDeliveryDate: selectedForm.enableEstimatedDeliveryDate,
              estimatedDeliveryDate: selectedForm.estimatedDeliveryDate,
            },
            deliveryText: {
              enableEstimatedDeliveryText: selectedForm.enableEstimatedDeliveryText,
              estimatedDeliveryText: selectedForm.estimatedDeliveryText,
            },
          },
        },
        additionalOptions: {
          enableBillingAddress: selectedForm.enableBillingAddress,
          enableInteractiveMap: selectedForm.enableInteractiveMap,
          enableInputObn: selectedForm.enableInputObn,
          enableAcceptingOrders: selectedForm.enableAcceptingOrders,
          privacyPolicy: {
            enablePrivacyPolicy: selectedForm.enablePrivacyPolicy,
            enablePrivacyPolicyCheckbox: selectedForm.enablePrivacyPolicyCheckbox,
            privacyPolicyText: selectedForm.privacyPolicyText,
          },
          termsAndConditions: {
            enableTermsAndConditions: selectedForm.enableTermsAndConditions,
            enableTermsAndConditionsCheckbox:
              selectedForm.enableTermsAndConditionsCheckbox,
            termsAndConditionsText: selectedForm.termsAndConditionsText,
          },
        },
        productListing: {
          version: selectedForm.version,
          productSections: selectedForm.FormProductSection.map((item: any) => {
            const pieces = item.pieces ? item.pieces.split(',') : ['0', '0', '0'];
            return {
              ...item,
              FormProduct: item.FormProduct.map((prod: any) => ({...prod, fieldDisabled: prod.productId && selectedForm.hasOrders})),
              fieldDisabled: item.sectionId && selectedForm.hasOrders,
              pieces1: parseInt(pieces[0]),
              pieces2: parseInt(pieces[1]),
              pieces3: parseInt(pieces[2]) 
            };
          }),
        },
      }
    : {};

  return (
    <OrderFormsComponent
      companyList={companyList}
      onCompanySelect={onCompanySelect}
      selectedForm={selectedForm}
      selectedCompany={selectedCompany}
      onSubmit={onSubmit}
      initialData={formatInputFormData}
    />
  );
};

export default OrderFormsContainer;
