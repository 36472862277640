import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AppContainer from './app/app.container';
import { store } from './store';

import './index.css';
import 'antd/dist/antd.css';
import './utils/axios';
import { theme } from './themes/theme';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <AppContainer />
      </React.StrictMode>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
