import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AppTheme } from '../../themes/theme';

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ErrorTitle = styled(Title)`
  text-align: center;
  color: #fff !important;
  font-size: 72px !important;
  margin-bottom: 0px !important;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #fff;
  font-size: 48px;
`;

const Error404 = () => {
  const { t } = useTranslation();
  
  return (
    <Container>
      <ErrorTitle>404</ErrorTitle>
      <Subtitle>{t('pageNotFound')}</Subtitle>
    </Container>
  )
};

export default Error404;
