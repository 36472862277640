import { TFunction } from 'react-i18next';
import patterns from '../../../utils/patterns';

const ProductSchema = (t: TFunction<'translation'>) => ({
  type: 'array',
  title: t('form.products'),
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: t('form.name'),
      },
      details: {
        type: 'string',
        title: t('form.details'),
      },
      weight: {
        type: 'number',
        title: t('form.weightInGrams'),
      },
      disabled: {
        type: 'boolean',
        title: t('form.hideInForm'),
      },
    },
    required: ['name', 'details', 'weight'],
  },
});

export const orderFormSchema = (t: TFunction<'translation'>, companyId: number) => ({
  type: 'object',
  title: 'Beschreibung',
  properties: {
    companyId: {
      type: 'number',
      default: companyId,
    },
    formId: {
      type: ['number', 'null'],
      default: undefined,
    },
    urlKey: {
      type: 'string',
      title: t('form.urlKey'),
      pattern: patterns.slug,
    },
    title: {
      type: 'string',
      title: t('form.title'),
    },
    body: {
      type: 'object',
      title: 'Hauptteil',
      properties: {
        headings: {
          type: 'object',
          title: t('form.headings'),
          properties: {
            heading1: {
              type: 'object',
              title: t('form.heading1'),
              properties: {
                enableHeading1: {
                  type: 'boolean',
                  title: t('form.enableHeading1'),
                  default: false,
                },
              },
              dependencies: {
                enableHeading1: {
                  oneOf: [
                    {
                      properties: {
                        enableHeading1: {
                          enum: [true],
                        },
                        headingTitle1: {
                          type: 'string',
                          title: t('form.headingTitle'),
                          default: '',
                        },
                        headingText1: {
                          type: 'string',
                          title: t('form.headingText'),
                          default: '',
                        },
                      },
                      required: ['headingTitle1', 'headingText1'],
                    },
                    {
                      properties: {
                        enableHeading1: {
                          enum: [false],
                        },
                      },
                    },
                  ],
                },
              },
            },
            heading2: {
              type: 'object',
              title: t('form.heading2'),
              properties: {
                enableHeading2: {
                  type: 'boolean',
                  title: t('form.enableHeading2'),
                  default: false,
                },
              },
              dependencies: {
                enableHeading2: {
                  oneOf: [
                    {
                      properties: {
                        enableHeading2: {
                          enum: [true],
                        },
                      
                        headingText2: {
                          type: 'string',
                          title: t('form.headingText'),
                          default: '',
                        },
                      },
                      required: ['headingText2'],
                    },
                    {
                      properties: {
                        enableHeading2: {
                          enum: [false],
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        dates: {
          type: 'object',
          title: 'Daten',
          properties: {
            orderStart: {
              type: 'string',
              title: t('form.orderStart'),
            },
            orderEnd: {
              type: 'string',
              title: t('form.orderEnd'),
            },
            deliveryDate: {
              type: 'object',
              title: '',
              properties: {
                enableEstimatedDeliveryDate: {
                  type: 'boolean',
                  title: t('form.enableEstimatedDeliveryDate'),
                  default: false,
                },
              },
              dependencies: {
                enableEstimatedDeliveryDate: {
                  oneOf: [
                    {
                      properties: {
                        enableEstimatedDeliveryDate: {
                          enum: [true],
                        },
                        estimatedDeliveryDate: {
                          type: 'string',
                          title: t('form.estimatedDeliveryDate'),
                        },
                      },
                      required: ['estimatedDeliveryDate'],
                    },
                    {
                      properties: {
                        enableEstimatedDeliveryDate: {
                          enum: [false],
                        },
                      },
                    },
                  ],
                },
              },
            },
            deliveryText: {
              type: 'object',
              title: '',
              properties: {
                enableEstimatedDeliveryText: {
                  type: 'boolean',
                  title: t('form.enableEstimatedDeliveryText'),
                  default: false,
                },
              },
              dependencies: {
                enableEstimatedDeliveryText: {
                  oneOf: [
                    {
                      properties: {
                        enableEstimatedDeliveryText: {
                          enum: [true],
                        },
                        estimatedDeliveryText: {
                          type: 'string',
                          title: t('form.estimatedDeliveryText'),
                        },
                      },
                      required: ['estimatedDeliveryText'],
                    },
                    {
                      properties: {
                        enableEstimatedDeliveryText: {
                          enum: [false],
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
          required: ['orderStart', 'orderEnd'],
        },
      },
      required: [],
    },
    additionalOptions: {
      type: 'object',
      title: t('form.additionalOptions'),
      properties: {
        enableBillingAddress: {
          type: 'boolean',
          title: t('form.enableBillingAddress'),
          default: false,
        },
        enableInteractiveMap: {
          type: 'boolean',
          title: t('form.enableInteractiveMap'),
          default: false,
        },
        enableInputObn: {
          type: 'boolean',
          title: t('form.enableInputObn'),
          default: false,
        },
        enableAcceptingOrders: {
          type: 'boolean',
          title: t('form.enableAcceptingOrders'),
          default: false,
        },
        privacyPolicy: {
          type: 'object',
          title: t('form.privacyPolicy'),
          properties: {
            enablePrivacyPolicy: {
              type: 'boolean',
              title: t('form.enablePrivacyPolicy'),
              default: false,
            },
          },
          dependencies: {
            enablePrivacyPolicy: {
              oneOf: [
                {
                  properties: {
                    enablePrivacyPolicy: {
                      enum: [true],
                    },
                    enablePrivacyPolicyCheckbox: {
                      type: 'boolean',
                      title: t('form.enablePrivacyPolicyCheckbox'),
                      default: false,
                    },
                    privacyPolicyText: {
                      type: 'string',
                      title: t('form.privacyPolicyText'),
                    },
                  },
                  required: ['privacyPolicyText'],
                },
                {
                  properties: {
                    enablePrivacyPolicy: {
                      enum: [false],
                    },
                  },
                },
              ],
            },
          },
        },
        termsAndConditions: {
          type: 'object',
          title: 'Allgemeine Geschäftsbedingungen',
          properties: {
            enableTermsAndConditions: {
              type: 'boolean',
              title: t('form.enableTermsAndConditions'),
              default: false,
            },
          },
          dependencies: {
            enableTermsAndConditions: {
              oneOf: [
                {
                  properties: {
                    enableTermsAndConditions: {
                      enum: [true],
                    },
                    enableTermsAndConditionsCheckbox: {
                      type: 'boolean',
                      title: t('form.enableTermsAndConditionsCheckbox'),
                      default: false,
                    },
                    termsAndConditionsText: {
                      type: 'string',
                      title: t('form.termsAndConditionsText'),
                    },
                  },
                  required: ['termsAndConditionsText'],
                },
                {
                  properties: {
                    enableTermsAndConditions: {
                      enum: [false],
                    },
                  },
                },
              ],
            },
          },
          required: ['enableTermsAndConditions'],
        },
      },
    },
    productListing: {
      type: 'object',
      title: t('form.productListing'),
      properties: {
        version: {
          type: 'number',
          title: t('form.version'),
          enum: [1, 2],
          enumNames: [
            "1 = Auflagen vorgeben",
            "2 = Auflagen frei wählbar lassen",
          ]
        },
      },
      required: ['version'],
      dependencies: {
        version: {
          oneOf: [
            {
              properties: {
                version: {
                  enum: [1],
                },
                productSections: {
                  type: 'array',
                  title: t('form.productSections'),
                  minItems: 1,
                  items: {
                    type: 'object',
                    properties: {
                      title: {
                        type: 'string',
                        title: t('form.title'),
                      },
                      pieces1: {
                        type: 'number',
                        title: t('form.pieces1'),
                        minimum: 1,
                        multipleOf: 1,
                        pattern: patterns.commaNumeric
                      },
                      pieces2: {
                        type: 'number',
                        title: t('form.pieces2'),
                        minimum: 1,
                        multipleOf: 1
                      },
                      pieces3: {
                        type: 'number',
                        title: t('form.pieces3'),
                        minimum: 1,
                        multipleOf: 1
                      },
                      FormProduct: ProductSchema(t),
                    },
                    required: ['title', 'pieces1', 'pieces2', 'pieces3', 'FormProduct'],
                  },
                },
              },
            },
            {
              properties: {
                version: {
                  enum: [2],
                },
                productSections: {
                  type: 'array',
                  title: t('form.productSections'),
                  items: {
                    type: 'object',
                    properties: {
                      title: {
                        type: 'string',
                        title: t('form.title'),
                      },
                      description: {
                        type: 'string',
                        title: t('form.description'),
                      },
                      FormProduct: ProductSchema(t),
                    },
                    required: ['title', 'FormProduct'],
                  },
                },
              },
              required: ['productSections'],
            },
          ],
        },
      },
    },
  },
  required: ['title', 'urlKey'],
});

export const orderFormUISchema = {
  companyId: {
    'ui:widget': 'hidden',
  },
  formId: {
    'ui:widget': 'hidden',
  },
  body: {
    headings: {
      heading1: {
        headingText1: {
          'ui:widget': 'textarea',
        },
      },
      heading2: {
        headingText2: {
          'ui:widget': 'textarea',
        },
      },
    },
    dates: {
      orderStart: {
        'ui:widget': 'date',
      },
      orderEnd: {
        'ui:widget': 'date',
      },
      deliveryDate: {
        estimatedDeliveryDate: {
          'ui:widget': 'date',
        },
      },
    },
  },
  additionalOptions: {
    privacyPolicy: {
      privacyPolicyText: {
        'ui:widget': 'textarea',
      },
    },
    termsAndConditions: {
      termsAndConditionsText: {
        'ui:widget': 'textarea',
      },
    },
  },
  productListing: {
    productSections: {
      items: {
        FormProduct: {
          items: {
            details: {
              'ui:widget': 'textarea',
            },
          },
        },
      },
    },
  },
};
