export default {
  ROOT: '/',
  LOGIN: process.env.REACT_APP_PATH_LOGIN || '/login',
  ADMIN_ROOT: process.env.REACT_APP_PATH_ADMIN_ROOT || '/admin',
  FORMS: process.env.REACT_APP_PATH_ADMIN_FORMS || '/admin/forms',
  ORDERS: process.env.REACT_APP_PATH_ADMIN_ORDERS || '/admin/view-orders',
  PUBLIC_ROOT: process.env.REACT_APP_PATH_PUBLIC || '/public',
  PUBLIC_ORDER: `${process.env.REACT_APP_PATH_PUBLIC}/:urlKey` || 'public/:urlKey',

  PAGE_404: '/404',
  PAGE_ORDER_SUCCESS: '/success',
  PAGE_ORDER_SUCCESS_PREORDER: '/success-preorder',
};
