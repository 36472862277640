import { notification } from 'antd';
import { translation } from '../translation';

export enum notificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

const title = {
  [notificationType.SUCCESS]: translation.success,
  [notificationType.ERROR]: translation.error,
  [notificationType.WARNING]: translation.warning,
};

export const showNotification = (type: notificationType, message?: string) => {
  notification[type]({
    message: title[type],
    description:
      type === notificationType.ERROR && !message ? translation.somethingWentWrong : message,
    placement: 'bottomRight',
  });
};
