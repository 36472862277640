import React, { useCallback } from 'react';
import { FieldProps } from '@rjsf/core';
import styled from 'styled-components';
import { Row, Col, List, Radio } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FormProduct, FormProductSection } from '../../../../store/types';
import Linker from '../../linker';
import { AppTheme } from '../../../../themes/theme';
import { useTranslation } from 'react-i18next';
import parseStringBrackets from '../../../../utils/parse-string-brackets';
import Parser from 'html-react-parser';

const Container = styled.div`

`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const ColCenter = styled(Col)`
  text-align: center;
`;

const ColRight = styled(Col)`
  text-align: right;
  margin-right: 16px;
`;

const ColFlex = styled(ColCenter)`
  flex: 1;
`;

const ColFlexBorder = styled(ColCenter)`
  flex: 1;
  border-left: 1px solid #d9d9d9;
`;

const RadioRow = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  width: inherit;
`;

const RadioItem = styled(Checkbox)`
  width: fit-content;
  min-width: 0px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const CustomList = styled.div`
  .ant-list-header {
    background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-list-header,
  .ant-list-item {
    padding: 0px 0px 0px 24px;
  }
  border-radius: 8px;
`;

const ContainerTitle = styled.h3`
  margin: 16px 0px 24px 0px;
  font-weight: bold;
  font-size: 18px;
`;

const PleaseCheck = styled(ColFlex)`
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  background-color: #6f6b6b;
  border-top-right-radius: 8px;
`;

const Prewrap = styled.span`
  white-space: pre-wrap;
`;

type ChangeValue = (value: number, productId: number) => void;

export const Version1FormWidget = (props: FieldProps) => {
  const { onChange, formData } = props;
  const { t } = useTranslation();

  const onPieceSelect = useCallback(
    (pieces: number, productId: number) => {
      const shouldRemove = formData.orders.some(
        (item: any) => item.productId === productId && item.pieces === pieces,
      );
      const orderList = formData.orders.filter(
        (item: any) => item.productId !== productId,
      );

      if (!shouldRemove) {
        orderList.push({
          productId,
          pieces,
        });
      }

      onChange({
        ...formData,
        orders: orderList,
      });
    },
    [onChange, formData],
  );

  return (
    <Container>
      <ContainerTitle>{t('productSelection')}</ContainerTitle>
      {formData.sections.map((section: FormProductSection) => (
        <ProductSection
          key={section.sectionId}
          {...section}
          onPieceSelect={onPieceSelect}
          formData={formData}
        />
      ))}
    </Container>
  );
};

const ProductSection = ({
  title,
  pieces,
  FormProduct,
  onPieceSelect,
  formData,
}: FormProductSection & { onPieceSelect: ChangeValue; formData: any }) => {
  const piecesColumn = pieces?.split(',') || [];

  return (
    <Section>
      <Products
        title={title}
        onPieceSelect={onPieceSelect}
        products={FormProduct}
        piecesColumn={piecesColumn}
        formData={formData}
      />
    </Section>
  );
};

const Products = ({
  title,
  products,
  piecesColumn,
  onPieceSelect,
  formData,
}: {
  title: string;
  products: FormProduct[];
  piecesColumn: string[];
  onPieceSelect: ChangeValue;
  formData: any;
}) => {
  const { t } = useTranslation();
  const activeProducts = products.filter(item => !item.disabled);
  return (
    <CustomList>
      <List
        header={
          <>
            <Row>
              <Col span={17}>
                <Col style={{ position: 'absolute', top: 25 }}>
                  <Title>{title}</Title>
                </Col>
                <Col style={{ position: 'absolute', bottom: 10, right: 0 }}>
                  <ColRight>{t('pieces')}</ColRight>
                </Col>
              </Col>
              <Col span={7}>
                <PleaseCheck>{t('pleaseCheck')}</PleaseCheck>
                <Row>
                  {piecesColumn.map((piece) => (
                    <ColFlexBorder style={{ fontWeight: 'bold', padding: 8 }} key={piece}>
                      {piece}
                    </ColFlexBorder>
                  ))}
                </Row>
              </Col>
            </Row>
          </>
        }
        bordered
        style={{ borderRadius: 8 }}
        dataSource={activeProducts}
        renderItem={(row) => (
          <List.Item style={{ display: '-webkit-box' }}>
            <Col span={17} style={{ paddingTop: 8, paddingBottom: 8, width: '100%' }}>
              <Row style={{ wordBreak: 'break-word' }}>
                <Col style={{ fontWeight: 'bold', paddingRight:24 }} span={4}>
                  {parseStringBrackets(row.name)}
                </Col>
                <Col span={20}>
                  <Row style={{ paddingRight: 8, wordBreak: 'break-word' }}>
                    <Prewrap>{Parser(row.details)}</Prewrap>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={7} style={{width: '100%', display: 'flex', alignItems: 'stretch'}}>
              <RadioRow
                size="large"
                // onChange={(event) => onPieceSelect(event.target.value, row.productId)}
              >
                {piecesColumn.map((piece) => (
                  <ColFlexBorder key={piece}>
                    <RadioItem
                      onChange={(event: CheckboxChangeEvent) =>
                        onPieceSelect(event.target.value, row.productId)
                      }
                      value={parseInt(piece)}
                      checked={formData.orders.some(
                        (item: any) =>
                          item.productId === row.productId &&
                          item.pieces === parseInt(piece),
                      )}
                    />
                  </ColFlexBorder>
                ))}
              </RadioRow>
            </Col>
          </List.Item>
        )}
      />
    </CustomList>
  );
};
