import React from 'react';
import { Divider, Layout, Menu } from 'antd';
import { CheckSquareOutlined, FileTextOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import useAuth from '../../hooks/useAuth';
import appRoutes from '../app.routes';

import OrderFormsContainer from './orderForms/orderForms.container';
import PrivateRoute from '../common/privateRoute';
import ViewOrdersContainer from './viewOrders/viewOrders.container';
import SysadminLogo from '../../assets/logo.png';
import AppFooter from '../common/appFooter/appFooter.component';

const { Sider, Content, Footer: AntFooter } = Layout;

const Logo = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoCenter = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

const SideNavigation = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
`;

const AdminContent = styled(Layout)`
  margin-left: 260px;
  min-height: 100vh;
  padding: 0;
`;

const Body = styled(Content)`
  margin: 0;
  padding: 0;
`;

const Footer = styled(AntFooter)`
  text-align: left;
`;

const CompanyArea = styled.div`
  margin-top: 24px;
  color: #fff;
  flex-direction: column;
`;

const CompanyName = styled(Title)`
  color: #fff !important;
  margin-bottom: 0px !important;
  text-align: center;
  margin-top: 16px;
  font-size: 14px !important;
`;

const SideDivider = styled(Divider)`
  border-top: 1px solid #cacaca;
  margin: 24px 0 0 0;
`;

const AdminComponent = () => {
  const { logout, isSysadmin, auth } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const currRoute = history.location.pathname;

  if(currRoute === appRoutes.ADMIN_ROOT || currRoute === appRoutes.ADMIN_ROOT + "/"){
    if(isSysadmin){
      history.replace(appRoutes.FORMS);
    }else{
      history.replace(appRoutes.ORDERS);
    }
  }

  const navigations = [
    {
      key: 'forms',
      sysadmin: true,
      icon: <FileTextOutlined />,
      label: t('navigation.orderForms'),
      path: appRoutes.FORMS,
    },
    {
      key: 'orders',
      icon: <CheckSquareOutlined />,
      label: t('navigation.viewOrders'),
      path: appRoutes.ORDERS,
    },
    { key: 'logout', icon: <LogoutOutlined />, label: t('navigation.logout') },
  ];

  return (
    <>
      <Layout>
        <SideNavigation width={260}>
          <Logo>
            {isSysadmin ? (
              <CompanyArea>
                <img alt="" src="https://newprint-blue.de/wp-content/uploads/2018/07/newprint_logo.png" width={120} />
                <CompanyName level={4}>{t('admin')}</CompanyName>
              </CompanyArea>
            ) : (
              <CompanyArea>
                <LogoCenter><img alt="" src={auth.company?.logoUrl} width={120} /></LogoCenter>
                <CompanyName level={4}>{auth.company?.name}</CompanyName>
              </CompanyArea>
            )}
          </Logo>

          <SideDivider />

          <Menu theme="dark" mode="inline">
            {navigations.map((nav) => {
              if (!nav.sysadmin || (isSysadmin && nav.sysadmin)) {
                const onNavPress = () => {
                  if (nav.key === 'logout') {
                    logout();
                  } else if (nav.path) {
                    history.push(nav.path);
                  }
                  return '';
                };
                return (
                  <Menu.Item onClick={onNavPress} key={nav.key} icon={nav.icon}>
                    {nav.label}
                  </Menu.Item>
                );
              }

              return '';
            })}
          </Menu>
        </SideNavigation>

        <AdminContent>
          <Body>
            {/* Insert Routes here */}
            {isSysadmin && (
              <Route path={appRoutes.FORMS}>
                <OrderFormsContainer />
              </Route>
            )}

            <PrivateRoute path={appRoutes.ORDERS}>
              <ViewOrdersContainer />
            </PrivateRoute>
          </Body>
          <Footer style={{ padding: 0 }}>
            <AppFooter position="relative" />
          </Footer>
        </AdminContent>
      </Layout>
    </>
  );
};

export default AdminComponent;
