import React, { useCallback } from 'react';
import { FieldProps } from '@rjsf/core';
import styled from 'styled-components';
import { Row, Col, List, InputNumber } from 'antd';
import { FormProduct, FormProductSection } from '../../../../store/types';
import Linker from '../../linker';
import { AppTheme } from '../../../../themes/theme';
import { useTranslation } from 'react-i18next';
import parseStringBrackets from '../../../../utils/parse-string-brackets';

const Container = styled.div`
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const ContainerTitle = styled.h3`
  margin: 16px 0px 24px 0px;
  font-weight: bold;
  font-size: 18px;
`;

const CustomList = styled(List)`
  .ant-list-header {
    background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  border-radius: 8px;
`;

const Description = styled.h4`
  color: #fff;
  margin-bottom: 0px;
`;

const Prewrap = styled.span`
  white-space: pre-wrap;
`;

type ChangeValue = (value: number, productId: number) => void;

export const Version2FormWidget = (props: FieldProps) => {
  const { onChange, formData } = props;
  const { t } = useTranslation();

  const onQtyInput = useCallback(
    (value: number, productId: number) => {
      if (Number.isInteger(value)) {
        const orderList = formData.orders.filter(
          (item: any) => item.productId !== productId,
        );
        orderList.push({
          productId,
          pieces: value,
        });

        onChange({
          ...formData,
          orders: orderList,
        });
      }
    },
    [onChange, formData],
  );

  return (
    <Container>
      <ContainerTitle>{t('productSelection')}</ContainerTitle>
      {formData.sections.map((section: FormProductSection) => (
        <ProductSection
          key={section.sectionId}
          {...section}
          onQtyInput={onQtyInput}
          orders={formData.orders}
        />
      ))}
    </Container>
  );
};

const ProductSection = ({
  title,
  FormProduct,
  onQtyInput,
  description,
  orders
}: FormProductSection & { onQtyInput: ChangeValue, orders: any }) => {
  return (
    <Section>
      <Products
        title={title}
        description={description}
        onQtyInput={onQtyInput}
        products={FormProduct}
        orders={orders}
      />
    </Section>
  );
};

const Products = ({
  products,
  onQtyInput,
  title,
  description,
  orders
}: {
  products: FormProduct[];
  onQtyInput: ChangeValue;
  title: string;
  description: string | null;
  orders: any
}) => {
  return (
    <>
      <CustomList
        header={
          <>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </>
        }
        bordered
        dataSource={products}
        renderItem={(row: any) => {
          const orderItem = orders.filter((order: any) => order.productId === row.productId);
          const orderValue = orderItem.length > 0 ? orderItem[0].pieces : 0;

          return (
            <List.Item>
              <Col span={5}>
                <InputNumber
                  style={{ borderRadius: 8 }}
                  size="large"
                  min={0}
                  max={10000}
                  defaultValue={0}
                  value={orderValue}
                  onChange={(value) => onQtyInput(value, row.productId)}
                />
              </Col>
              <Col span={8}>
                <b>{parseStringBrackets(row.name)}</b>
              </Col>
              <Col span={11}>
                <Row>
                  <Prewrap><Linker>{row.details}</Linker></Prewrap>
                </Row>
              </Col>
            </List.Item>
          )
        }}
      />
    </>
  );
};
