import React, { useCallback } from 'react';
import { FieldProps } from '@rjsf/core';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import Linker from '../../linker';

export const HtmlLinkCheckboxWidget = (props: FieldProps) => {
  const { schema, onChange, formData } = props;
  const schemaTitle = schema.title || '';

  const handleChange = useCallback(
    (value: CheckboxChangeEvent) => {
      onChange(value.target.checked);
    },
    [onChange],
  );

  if (schema.format === 'html') {
    return (
      <div
        style={{ whiteSpace: 'pre-wrap', marginLeft: 24 }}
        dangerouslySetInnerHTML={{ __html: schemaTitle }}
      />
    );
  }

  if (schema.readOnly) {
    return (
      <div style={{ whiteSpace: 'pre-wrap', marginLeft: 24 }}>
        <Linker>{schemaTitle}</Linker>
      </div>
    );
  }

  return (
    <Checkbox
      style={{ whiteSpace: 'pre-wrap' }}
      checked={formData}
      onChange={handleChange}
    >
      <Linker>{schemaTitle}</Linker>
    </Checkbox>
  );
};
