import { Button, Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Error404 from './404';
import Logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { orderSliceActions } from '../../store/slices/order';
import AppFooter from '../common/appFooter/appFooter.component';
import CryptoJS from "crypto-js";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SuccessTitle = styled(Title)`
  font-size: 40px !important;
  margin-bottom: 32px !important;
`;

const DownloadButton = styled(Button)`
  font-size: 14px;
  height: 40px;
  padding: 0px 25px;
  font-weight: bold;
  margin-top: 25px;
`;

const LogoContainer = styled.img`
  width: 300px;
  margin-bottom: 40px;
`;

const OrderPreorder = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);

  const id = query.get('id');

  if (!id){
    return <Error404 />;
  }

  const bytes  = CryptoJS.Rabbit.decrypt(id, 'test');
  const rawParam = bytes.toString(CryptoJS.enc.Utf8);

  const {formKey, orderId} = JSON.parse(rawParam);

  if (!formKey || formKey === '' || !orderId || orderId === '') {
    return <Error404 />;
  }

  const initDownloadReceipt = () => {
    if (orderId) {
      dispatch(
        orderSliceActions.downloadOrderReceipt({
          orderId: parseInt(orderId)
        }),
      );
    }
  };

  const LoginCard = styled(Card)`
    width: 60%;
    min-width: 400px;
    max-width: 750px;
    border-radius: 16px;
  `;

  const Body = styled.div`
    border: none;
    display: block;
    width: 100%;
    align-items: center;
  `;
    
    return (
      <Container>
        <LoginCard>
        <SuccessTitle>Als Vorbestellung vermerkt.</SuccessTitle>
          <Body>
            Ihre Bestellung ist nach Ablauf der Bestellfrist eingegangen. Ob Ihre Bestellung noch ausgeführt werden kann hängt davon ab, ob der Aktionsbetreiber einen weiteren Versand von Ware nach Ablauf des Bestellendes wünscht und noch genügend Produkte auf Lager sind. Sollte beides gegeben sein, wird Ihre Bestellung regulär verarbeitet und in der angegeben Zeit bei Ihnen eintreffen. Bitte sehen Sie davon ab, uns zum Stand Ihrer Bestellung zu kontaktieren.
          </Body>
          <DownloadButton 
            type="primary"
            onClick={initDownloadReceipt}
          >
          {t('downloadSummary')}
          </DownloadButton>
        </LoginCard>
      </Container>
    );

}

export default OrderPreorder;