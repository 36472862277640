import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import { RootState } from '../../../store';
import { companySliceActions } from '../../../store/slices/company';
import { formSliceActions } from '../../../store/slices/form';
import { orderSliceActions } from '../../../store/slices/order';
import ViewOrdersComponent from './viewOrders.component';

const ViewOrdersContainer = () => {
  const dispatch = useDispatch();
  const { isSysadmin, auth } = useAuth();
  const [currCompany, setCurrCompany] = useState<number | undefined>(undefined);
  const [currPage, setCurrPage] = useState(1);
  const [currSort, setCurrSort] = useState({
    key: 'orderId',
    label: 'Order No.',
  });
  const [currDirection, setCurrDirection] = useState('asc');

  const { companyList, orderList, pages, selectedForm } = useSelector(
    (state: RootState) => ({
      companyList: state.company.list,
      orderList: state.orders.list,
      pages: state.orders.pages,
      selectedForm: state.orders.selectedForm,
    }),
  );

  useEffect(() => {
    if (!isSysadmin) {
      setCurrCompany(auth.companyId);
    }
  }, [auth.companyId, isSysadmin]);

  useEffect(()=>{
    dispatch(orderSliceActions.clearSelection());
  },[dispatch])

  useEffect(() => {
    if (isSysadmin) {
      dispatch(companySliceActions.getCompanies());
    }
  }, [isSysadmin, dispatch]);

  const onCompanySelect = (value: SelectValue) => {
    setCurrCompany(value as number);
    setCurrPage(1);
  };

  const onPageChange = (value: number) => {
    setCurrPage(value);
  };

  const onSortChange = (key: string, label: string) => {
    if (key === currSort.key) {
      setCurrDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setCurrSort({ key, label });
      setCurrDirection('asc');
    }
  };

  useEffect(() => {
    if (currCompany) {
      dispatch(
        orderSliceActions.getCompanyFormOrders({
          companyId: currCompany,
          page: currPage,
          sortKey: currSort.key,
          direction: currDirection,
        }),
      );
    }
  }, [currSort, currPage, currCompany, currDirection, dispatch]);

  return (
    <ViewOrdersComponent
      companyList={companyList}
      onCompanySelect={onCompanySelect}
      selectedForm={selectedForm}
      orderList={orderList}
      pages={pages}
      currPage={currPage}
      currSort={currSort}
      currDirection={currDirection}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
    />
  );
};

export default ViewOrdersContainer;
