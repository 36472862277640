import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

// Add slices imports
import { authSlice } from './slices/auth';
import { companySlice } from './slices/company';
import { formSlice } from './slices/form';
import { orderSlice } from './slices/order';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    company: companySlice.reducer,
    form: formSlice.reducer,
    orders: orderSlice.reducer,
  },
  enhancers: [applyMiddleware(thunkMiddleware)],
});

export type RootState = ReturnType<typeof store.getState>;
