import React from 'react';
import { FieldProps } from '@rjsf/core';
import ReCAPTCHA from "react-google-recaptcha";


export const RecaptchaWidget = (props: FieldProps) => {
      const { onChange } = props;

    return (
        <ReCAPTCHA
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            sitekey="6LcEfTMbAAAAABJZLukpl8TF6FL5B1pnSe_a99d4" //prod
            onChange={onChange}
        />
    )
}
