import { TFunction } from 'react-i18next';
import { FormType } from '../../store/types';
import patterns from '../../utils/patterns';
import Linker from '../common/linker';

const setOrderData = (t: TFunction<'translation'>) => ({
  type: 'object',
  title: t('form.orderData'),
  properties: {
    companyName: {
      type: 'string',
      title: t('form.companyName'),
    },
    nameSuffix: {
      type: 'string',
      title: t('form.nameSuffix'),
    },
    firstName: {
      type: 'string',
      title: t('form.firstName'),
    },
    lastName: {
      type: 'string',
      title: t('form.lastName'),
    },
    email: {
      type: 'string',
      title: t('form.email'),
      format: 'email',
      pattern: patterns.email,
    },
    telephoneNumber: {
      type: 'string',
      title: t('form.telephoneNumber'),
      pattern: patterns.telephone
    },
    street: {
      type: 'string',
      title: t('form.street'),
    },
    houseNumber: {
      type: 'string',
      title: t('form.houseNumber'),
    },
    postCode: {
      type: 'string',
      title: t('form.postCode'),
      pattern: patterns.numeric,
      // minLength: 5,
      // maxLength: 5
    },
    city: {
      type: 'string',
      title: t('form.city'),
    },
    country: {
      type: 'string',
      title: t('form.country'),
      pattern: patterns.alphabets
    },
  },
  // required: ['companyName', 'firstName', 'lastName', 'email', 'telephoneNumber','street', 'houseNumber', 'postCode', 'city', 'country'],
  required: [
    'firstName'
    , 'lastName'
    , 'telephoneNumber'
    ,'street'
    , 'houseNumber'
    , 'postCode'
    , 'city'
    , 'country'],
});

const setBillingAddress = (t: TFunction<'translation'>, form: FormType) => {
  if (form.enableBillingAddress) {
    return {
      type: 'object',
      title: 'Rechnungsadresse',
      properties: {
        billingCompanyName: {
          type: 'string',
          title: t('form.companyName'),
        },
        billingNameSuffix: {
          type: 'string',
          title: t('form.nameSuffix'),
        },
        billingFirstName: {
          type: 'string',
          title: t('form.firstName'),
        },
        billingLastName: {
          type: 'string',
          title: t('form.lastName'),
        },
        billingEmail: {
          type: 'string',
          title: 'Email',
          format: t('form.email'),
          pattern: patterns.email,
        },
        billingStreet: {
          type: 'string',
          title: t('form.street'),
        },
        billingHouseNumber: {
          type: 'string',
          title: t('form.houseNumber'),
        },
        billingPostCode: {
          type: 'string',
          title: t('form.postCode'),
          pattern: patterns.numeric,
          minLength: 5,
          maxLength: 5
        },
        billingCity: {
          type: 'string',
          title: t('form.city'),
        },
        billingCountry: {
          type: 'string',
          title: t('form.country'),
          pattern: patterns.alphabets
        },
      },
      required: [
        // 'billingCompanyName',
        'billingFirstName',
        'billingLastName',
        // 'billingEmail',
        'billingStreet',
        'billingHouseNumber',
        'billingPostCode',
        'billingCity',
        'billingCountry',
      ],
    };
  }

  return {};
};

const setOthers = (t: TFunction<'translation'>, form: FormType) => {
  if (form.enableInteractiveMap || form.enableInputObn) {
    const isInteractiveMap = form.enableInteractiveMap
      ? {
          type: 'boolean',
          title: t('form.partInteractiveMap'),
          default: false,
        }
      : {};

    const obn = form.enableInputObn
      ? {
          type: 'string',
          title: t('form.obn'),
        }
      : {};
    
    return {
      type: 'object',
      title: '',
      properties: {
        isInteractiveMap,
        obn
      },
    };
  }

  return {};
};

const setAcceptCheckboxes = (t: TFunction<'translation'>, form: FormType) => {
  const termsAndCondition = form.enableTermsAndConditions
    ? {
        type: 'boolean',
        title: form.termsAndConditionsText,
        default: !form.enableTermsAndConditionsCheckbox,
        readOnly: !form.enableTermsAndConditionsCheckbox,
      }
    : {};

  const privacyPolicy = form.enablePrivacyPolicy
    ? {
        type: 'boolean',
        title: form.privacyPolicyText,
        default: !form.enablePrivacyPolicyCheckbox,
        readOnly: !form.enablePrivacyPolicyCheckbox,
      }
    : {};

  return {
    type: 'object',
    title: '',
    properties: {
      privacyPolicy,
      termsAndCondition
    },
  };
};

const setProductSelection = (t: TFunction<'translation'>) => {
  // Check form versions
  return {
    type: 'object',
    title: t('form.orderItems'),
  };
};

export const orderSchema = (t: TFunction<'translation'>, form: FormType) => {
  const billingAddress = setBillingAddress(t, form);
  const others = setOthers(t, form);
  const acceptCheckboxes = setAcceptCheckboxes(t, form);
  const productSelection = setProductSelection(t);
  //const deliveryAddress = setDeliveryAddress(t);
  const orderData = setOrderData(t);

  return {
    type: 'object',
    title: `${t('form.orderDetails')}`,
    properties: {
      orderData,
      //deliveryAddress,
      billingAddress,
      productSelection,
      others,
      recaptcha: {
        type: 'string'
      },
      acceptCheckboxes,
    },
    required: ['recaptcha']
  };
};

export const orderUISchema = (version: number) => ({
  others: {
    classNames: "noPaddingVertical",
    isInteractiveMap: {
      'ui:field': 'HtmlLinkCheckboxWidget',
      classNames: "noPaddingVertical"
    },
  },
  recaptcha: {
    classNames: "recaptchaPadding",
    "ui:options": {label: false},
    'ui:field': 'RecaptchaWidget',
  },
  acceptCheckboxes: {
    classNames: "noPaddingTop",
    termsAndCondition: {
      'ui:field': 'HtmlLinkCheckboxWidget',
    },
    privacyPolicy: {
      'ui:field': 'HtmlLinkCheckboxWidget',
    },
  },
  productSelection: {
    'ui:field': version === 1 ? 'Version1FormWidget' : 'Version2FormWidget',
  },
});
