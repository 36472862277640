import React from 'react';
import styled from 'styled-components';
import { AppTheme } from '../../../themes/theme';

const Container = styled.div`
  height: auto;
`;

const Header = styled.h4`
  font-size: 24px;
  background-color: #fff;
  color: ${({ theme }: { theme: AppTheme }) => theme.text.default};
  padding: 16px;
`;

const Body = styled.div`
  margin: 24px;
  padding: 24px;
  background-color: #fff;
`;

interface Props {
  title: string;
  children: any;
}

const ContentBody: React.FC<Props> = ({ children, title }) => {
  return (
    <Container>
      <Header>{title}</Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default ContentBody;
