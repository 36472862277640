import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { formSliceActions } from '../../store/slices/form';
import { orderSliceActions } from '../../store/slices/order';
import { FormType } from '../../store/types';
import { notificationType, showNotification } from '../../utils/notification';
import PublicOrderComponent from './publicOrderForm.component';

const PublicOrderContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedForm: FormType | undefined = useSelector(
    (state: RootState) => state.form.selectedPublicOrderForm,
  );

  // @ts-ignore
  const { urlKey }: { urlKey: string } = useParams();

  useEffect(() => {
    dispatch(formSliceActions.getFormByUrlKey(urlKey));
  }, [dispatch, urlKey]);

  const onSubmit = (formData: any) => {
    // If order has no orderitem, show notification
    const hasOrderItem =
      formData.productSelection.orders.length > 0 &&
      formData.productSelection.orders.some(
        (item: { pieces: number }) => item.pieces > 0,
      );

    if (!hasOrderItem) {
      showNotification(notificationType.WARNING, t('notif.atLeastOneOrder'));
    } else {
      // If checkboxes are not checked, show notification
      const acceptBoxes = formData.acceptCheckboxes;
      const hasUnacceptedBox = Object.keys(acceptBoxes).some(
        (boxKey) => !acceptBoxes[boxKey],
      );

      
      if (hasUnacceptedBox) {
        showNotification(notificationType.WARNING, t('notif.acceptAllTerms'));
      } else {
        const initdata = formData.orderData;
        if(!('companyName' in initdata) || !initdata['companyName'])
        {
          initdata['companyName'] = '-';
        }

        if(!('email' in initdata) || !initdata['email'])
        {
          initdata['email'] = '-';
        }

        const orderData = {
          ...initdata,
          ...formData.billingAddress,
          ...formData.deliveryAddress,
          ...formData.others,
          OrderItem: formData.productSelection.orders,
          formId: selectedForm?.formId,
        };

        dispatch(orderSliceActions.submitPublicOrderForm({ orderData, urlKey }));
      }
    }
  };

  const initialData = {
    // Set initial values for productSelection
    productSelection: {
      orders: [],
      sections: selectedForm?.FormProductSection.map((section) => {
        return {
          sectionId: section.sectionId,
          title: section.title,
          description: section.description,
          pieces: section.pieces,
          FormProduct: section.FormProduct.map((product) => {
            return {
              productId: product.productId,
              link: product.link,
              details: product.details,
              name: product.name,
              sectionId: product.sectionId,
              disabled: product.disabled
            };
          }),
        };
      }),
    },
  };

  return (
    <PublicOrderComponent
      selectedForm={selectedForm}
      onSubmit={onSubmit}
      initialData={initialData}
    />
  );
};

export default PublicOrderContainer;
