import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { Divider, Row, Spin } from 'antd';
import styled from 'styled-components';
import ContentBody from '../../common/contentBody/contentBody.component';
import { CompanyType, FormType } from '../../../store/types';
import { orderFormSchema, orderFormUISchema } from './orderForms.schema';
import SchemaForm from '../../common/schemaForm/schemaForm.component';

import CompanySelect from '../../common/companyProjectSelect/companyProjectSelect.component';
import config from '../../../config';
import appRoutes from '../../app.routes';
import Title from 'antd/lib/typography/Title';

interface Props {
  companyList?: CompanyType[];
  onCompanySelect: (value: SelectValue) => void;
  selectedForm: FormType | undefined;
  selectedCompany: number | undefined;
  onSubmit: (formData: any) => void;
  initialData: any;
}

const QuickAccess = styled.h4`
  font-weight: bold;
  font-size: 18px;
  margin-left: 8px;
`;

const FormLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const OrderFormsComponent = ({
  companyList = [],
  onCompanySelect,
  selectedForm,
  selectedCompany = 0,
  onSubmit,
  initialData,
}: Props) => {
  const { t } = useTranslation();
  const orderSchema = orderFormSchema(t, selectedCompany);
  const formUrl = `${config.APP_DOMAIN}${appRoutes.PUBLIC_ROOT}/${selectedForm?.urlKey}`;

  return (
    <ContentBody title={t('navigation.orderForms')}>
      {companyList.length <= 0 ? (
        <Row>
          <Spin size="large" />
          <Title style={{marginLeft: 16}} level={5}>Loading</Title>
        </Row>
      ) : (
          <>
            <CompanySelect options={companyList} onSelect={onCompanySelect} />
            {(selectedCompany || selectedForm) && (
              <>
                <Divider />
                {selectedForm?.urlKey && (
                  <>
                    <QuickAccess>
                      <span>{t('formLink')}: </span>
                      <FormLink href={formUrl} target="_blank">
                        {formUrl}
                      </FormLink>
                    </QuickAccess>
                    <Divider />
                  </>
                )}
                <SchemaForm
                  schema={orderSchema}
                  uiSchema={orderFormUISchema}
                  onSubmit={onSubmit}
                  submitText={t('saveChanges')}
                  initialData={initialData}
                  liveValidate
                />
              </>
            )}
          </>
        )
      }

    </ContentBody>
  );
};

export default OrderFormsComponent;
