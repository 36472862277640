import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import styled from 'styled-components';
import SchemaForm from '../common/schemaForm/schemaForm.component';
import AppFooter from '../common/appFooter/appFooter.component';
import { AppTheme } from '../../themes/theme';

interface LoginProps {
  onSubmit: (formData: any) => void;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
  background-color: #e3e3e3;
`;

const LoginCard = styled(Card)`
  width: 45%;
  min-width: 400px;
  max-width: 550px;
  border-radius: 16px;
`;

const Title = styled.h4`
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }: { theme: AppTheme }) => theme.text.default};
  margin-bottom: 0px;
`;

const Body = styled.div`
  border: none;
  display: block;
  width: 100%;
  align-items: center;
`;

const LoginButton = styled(Button)`
  width: 250px;
  height: 40px;
  display: block;
  margin: 0 auto;
`;

const LoginComponent: React.FC<LoginProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const loginSchema = {
    type: 'object',
    properties: {
      username: {
        type: 'string',
        title: t('username'),
        name: 'username',
      },
      password: {
        type: 'string',
        title: t('password'),
      },
    },
    required: ['username', 'password'],
  };

  const loginUISchema = {
    password: {
      "ui:options": {
        inputType: 'password'
      }
    },
  };

  return (
    <Container>
      <LoginCard>
        <Title>{t('appName')}</Title>
        <Body>
          <SchemaForm
            schema={loginSchema}
            uiSchema={loginUISchema}
            onSubmit={onSubmit}
            submitText={t('login')}
            CustomSubmitBtn={LoginButton}
            hideObjectBorder
            hideNotifError
          />
        </Body>
      </LoginCard>
      <AppFooter />
    </Container>
  );
};

export default LoginComponent;
