import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { authSliceActions } from '../../store/slices/auth';
import appRoutes from '../app.routes';
import LoginComponent from './login.component';

const LoginContainer: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();

  const onSubmit = (formData: any) => {
    dispatch(
      authSliceActions.login({
        username: formData.username,
        password: formData.password,
      }),
    );
  };

  if (isAuthenticated) {
    return <Redirect to={appRoutes.ADMIN_ROOT} />;
  }

  return <LoginComponent onSubmit={onSubmit} />;
};

export default LoginContainer;
