import { AuthType } from '../store/types';

export const getAuth = () => {
  const authRaw = localStorage.getItem('auth');
  if (authRaw) {
    const auth = JSON.parse(authRaw);
    return auth;
  }
  return undefined;
};

export const setAuth = (auth: AuthType | null) => {
  if (!auth) {
    localStorage.removeItem('auth');
  } else {
    localStorage.setItem('auth', JSON.stringify(auth));
  }
};
