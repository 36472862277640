import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { authSliceActions } from '../store/slices/auth';
import { AuthType } from '../store/types';
import { setAuth } from '../utils/auth';

const useAuth = () => {
  const dispatch = useDispatch();

  const auth: AuthType = useSelector((state: RootState) => state.auth);

  const logout = () => {
    setAuth(null);
    dispatch(authSliceActions.logout());
  };

  const isSysadmin = auth.isSysadmin ?? false;

  return {
    auth,
    isAuthenticated: !!auth.id && !!auth.token,
    logout,
    isSysadmin,
  };
};

export default useAuth;
