import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { i18n as i18nType } from 'i18next';
import initI18n from '../i18n';
import appRoutes from './app.routes';
import PrivateRoute from './common/privateRoute';

import LoginContainer from './login/login.container';
import AdminContainer from './admin/admin.container';
import PublicOrderContainer from './publicOrderForm/publicOrderForm.container';
import OrderSuccess from './pages/orderSuccess';
import OrderPreorder from './pages/orderPreorder';
import Error404 from './pages/404';
import { AppTheme } from '../themes/theme';

const supportedLocales = ['en', 'de'];

const Container = styled.div`
  background-color: #fff;

  .ant-btn {
    border-radius: 8px;
  }

  // Change antd button themes
  .ant-btn-primary {
    background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
  }

  .ant-layout-sider {
  }

  div.ant-select-selector {
    border-radius: 8px !important;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 8px;
    font-size: 16px;
  }

  // Required asterisk styling fix
  .ant-form-item-required {
    flex-direction: row-reverse;
    width: auto;
    justify-content: flex-end;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-left: 4px;
  }
`;

const AppComponent: React.FC = () => {
  const [i18n, setI18n] = useState<i18nType | null>(null);
  const initLocales = useCallback(async () => {
    if (!i18n) {
      const i18nGlobalInstance = await initI18n(supportedLocales, 'en');
      setI18n(i18nGlobalInstance);
    }
  }, [i18n]);

  useEffect(() => {
    initLocales();
  }, [initLocales]);

  return (
    i18n && (
      <Container>
        <Router>
          <Switch>
            <Route exact path={appRoutes.LOGIN}>
              <LoginContainer />
            </Route>

            <Route path={appRoutes.PUBLIC_ORDER}>
              <PublicOrderContainer />
            </Route>

            <Route path={appRoutes.PAGE_ORDER_SUCCESS}>
              <OrderSuccess />
            </Route>

            <Route path={appRoutes.PAGE_ORDER_SUCCESS_PREORDER}>
              <OrderPreorder />
            </Route>

            {/* Should be last so above routes takes precedence */}
            <PrivateRoute path={appRoutes.ADMIN_ROOT}>
              <AdminContainer />
            </PrivateRoute>

            {/* Default for all unsupported routes */}
            <Route path={appRoutes.ROOT}>
              <Error404 />
            </Route>
          </Switch>
        </Router>
      </Container>
    )
  );
};

export default AppComponent;
