import { Button, Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Error404 from './404';
import Logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { orderSliceActions } from '../../store/slices/order';
import CryptoJS from "crypto-js";
import AppFooter from '../common/appFooter/appFooter.component';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SuccessTitle = styled(Title)`
  font-size: 40px !important;
  margin-bottom: 32px !important;
`;

const DownloadButton = styled(Button)`
  font-size: 14px;
  height: 40px;
  padding: 0px 25px;
  font-weight: bold;
  margin-top: 25px;
`;

const LogoContainer = styled.img`
  width: 300px;
  margin-bottom: 40px;
`;

const OrderSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);

  const id = query.get('id');

  if (!id){
    return <Error404 />;
  }


try{
  const bytes  = CryptoJS.Rabbit.decrypt(id, 'test');
  const rawParam = bytes.toString(CryptoJS.enc.Utf8);

  const {formKey, orderId} = JSON.parse(rawParam);

  if (!formKey || formKey === '' || !orderId || orderId === '') {
    return <Error404 />;
  }
  const initDownloadReceipt = () => {
    if (orderId) {
      dispatch(
        orderSliceActions.downloadOrderReceipt({
          orderId: parseInt(orderId)
        }),
      );
    }
  };

  const LoginCard = styled(Card)`
    width: 60%;
    min-width: 400px;
    max-width: 750px;
    border-radius: 16px;
  `;

  const Body = styled.div`
    border: none;
    display: block;
    width: 100%;
    align-items: center;
  `;
    
    return (
      <Container>
        <LoginCard>
        <SuccessTitle>Ihre Bestellung war erfolgreich.</SuccessTitle>
          <Body>
            Vielen Dank für Ihre Bestellung, welche nun von unserem Team verarbeitet wird. Änderungen sind nun nicht mehr möglich. Sie erhalten keine separate Bestellbestätigung per E-Mail. Bitte sehen Sie davon ab, uns zum Stand Ihrer Bestellung zu kontaktieren. Die Lieferung der bestellten Produkte erfolgt wie angegeben.
          </Body>
          <DownloadButton 
            type="primary"
            onClick={initDownloadReceipt}
          >
          {t('downloadSummary')}
          </DownloadButton>
        </LoginCard>
      </Container>
    );
  } catch(e){
    return <Error404/>
  }

}

export default OrderSuccess;