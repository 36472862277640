import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import appRoutes from '../../app/app.routes';
import { showNotification, notificationType } from '../../utils/notification';
import { FormType } from '../types';

const initialState: {
  selectedForm?: FormType & {hasOrders?: boolean};
  selectedCompany?: number;
  selectedPublicOrderForm?: FormType;
} = {
  // Empty initial form
};

const apiEndpoint = '/forms';

export const formSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setSelectedForm: (state, action: PayloadAction<FormType | undefined>) => {
      return {
        ...state,
        selectedForm: action.payload,
      };
    },
    setSelectedCompany: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        selectedCompany: action.payload,
      };
    },
    setSelectedPublicOrderForm: (state, action: PayloadAction<FormType | undefined>) => {
      return {
        ...state,
        selectedPublicOrderForm: action.payload,
      };
    },
    clearSelection: () => {
      return {
        ...initialState,
      };
    },
  },
});

const getCompanyForm = createAsyncThunk(
  '/getCompanyForm',
  async (payload: number, thunkAPI) => {
    const response = await axios.get(`${apiEndpoint}/company/${payload}`);
    const form = response.data.data;

    if (form) {
      thunkAPI.dispatch(formSliceActions.setSelectedForm(form));
    } else {
      thunkAPI.dispatch(formSliceActions.setSelectedForm());
    }

    // Set company selected for viewing
    thunkAPI.dispatch(formSliceActions.setSelectedCompany(payload));
  },
);

const getFormByUrlKey = createAsyncThunk(
  '/getFormByUrlKey',
  async (urlKey: string, thunkAPI) => {
    const response = await axios.get(`${apiEndpoint}/urlKey/${urlKey}`);
    const form = response.data.data;

    if (form) {
      thunkAPI.dispatch(formSliceActions.setSelectedPublicOrderForm(form));
    } else {
      thunkAPI.dispatch(formSliceActions.setSelectedPublicOrderForm());
      window.location.href = `${appRoutes.PAGE_404}`;
    }
  },
);

const submitCompanyForm = createAsyncThunk(
  '/submitCompanyForm',
  async (payload: Partial<FormType & {hasOrders?: boolean}>, thunkAPI) => {
    try {
      const response = await axios.post(apiEndpoint, payload);
      const form = response.data.data;
     
      if (form) {
        thunkAPI.dispatch(formSliceActions.setSelectedForm(form));
        showNotification(notificationType.SUCCESS, response.data.message);
        window.scrollTo(0, 0); // scroll back to top
      } else {
        // Error
        thunkAPI.dispatch(formSliceActions.setSelectedForm(payload as FormType));
        showNotification(notificationType.ERROR, response.data.message);
      }
    } catch {
      // Error
      thunkAPI.dispatch(formSliceActions.setSelectedForm(payload as FormType));
      showNotification(notificationType.ERROR);
    }
  },
);

export const formSliceActions = {
  ...formSlice.actions,
  getCompanyForm,
  getFormByUrlKey,
  submitCompanyForm,
};
