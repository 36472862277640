import { Card, Divider, Row, Col } from 'antd';
import React from 'react';

import styled from 'styled-components';
import moment from 'moment';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../store/types';
import { AppTheme } from '../../themes/theme';
import SchemaForm from '../common/schemaForm/schemaForm.component';
import { orderSchema, orderUISchema } from './publicOrderForm.schema';
import Linker from '../common/linker';
import AppFooter from '../common/appFooter/appFooter.component';
import config from '../../config';
import Parser from 'html-react-parser';

const BackContainer = styled.div`
  height: 160px;
  background-color: ${({ theme }: { theme: AppTheme }) => theme.primary};
  position: fixed;
  top: 0;
  width: 100%;
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
`;

const FormCard = styled(Card)`
  min-width: 750px;
  max-width: 60%;
  display: block;
  margin: 32px auto;
  padding: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormTitle = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }: { theme: AppTheme }) => theme.text.default};
  margin-top: 10px;
`;

const Headings = styled.div`
  margin: 24px 0px;
`;

const HeadingsTitle = styled.div`
  font-weight: bold;
  color: ${({ theme }: { theme: AppTheme }) => theme.text.default};
`;

const DateContainer = styled(Row)`
  margin-top: 16px;
`;

const DateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const DateTitle = styled.span`
  color: ${({ theme }: { theme: AppTheme }) => theme.text.default};
  margin-right: 12px;
  font-weight: bold;
`;

const Logo = styled.img`
  object-fit: contain;
  width: 185px;
`;

const Prewrap = styled.span`
  white-space: pre-wrap;
`;

interface Props {
  selectedForm: FormType | undefined;
  onSubmit: (formData: any) => void;
  initialData: Record<string, unknown> | undefined;
}

const PublicOrderComponent = ({ selectedForm: form, onSubmit, initialData }: Props) => {
  const { t } = useTranslation();

  if (!form) {
    return <p />;
  }

  const formOrderUiSchema = orderUISchema(form.version);
  const formOrderSchema = orderSchema(t, form);

  return (
    <>
      <BackContainer />

      <Container>
        <FormCard>
          <Header>
            <Logo src={form.company.logoUrl} />
          </Header>
          <FormTitle>
              {t('form.orderForm')}: {form.title}
          </FormTitle>
          {form.enableHeading1 && (
            <Headings>
              <HeadingsTitle>{form.headingTitle1}</HeadingsTitle>
              <Prewrap><Linker>{form.headingText1 || ''}</Linker></Prewrap>
            </Headings>
          )}

          {form.enableHeading2 && (
              Parser(form.headingText2 || '')
          )}

          {form.enableAcceptingOrders ? (
            <>
              <DateContainer style={{ marginTop: 16 }}>
                <Col span={12}>
                  <DateRow>
                    <DateTitle>{t('form.orderAcceptanceBegin')}: </DateTitle>
                    <span>{moment(form.orderStart).format(config.DATE_FORMAT)}</span>
                  </DateRow>

                  <DateRow>
                    <DateTitle>{t('form.orderAcceptanceEnd')}: </DateTitle>
                    <span>{moment(form.orderEnd).format(config.DATE_FORMAT)}</span>
                  </DateRow>
                </Col>

                <Col span={12}>
                  {form.enableEstimatedDeliveryDate && (
                    <DateRow>
                      <DateTitle>{t('form.estimatedDeliveryDate')}: </DateTitle>
                      <span>
                        {moment(form.estimatedDeliveryDate).format(config.DATE_FORMAT)}
                      </span>
                    </DateRow>
                  )}

                  {form.enableEstimatedDeliveryText && (
                    <DateRow>
                      <DateTitle>{t('form.estimatedDeliveryText')}: </DateTitle>
                      <span>{form.estimatedDeliveryText}</span>
                    </DateRow>
                  )}
                </Col>
              </DateContainer>

              <Divider />

              <SchemaForm
                schema={formOrderSchema}
                uiSchema={formOrderUiSchema}
                onSubmit={onSubmit}
                submitText={t('form.orderNow')}
                hideObjectBorder
                initialData={initialData}
                // liveValidate
              />
            </>
          ) : (
            <>
              <Divider />
              <Title level={3} style={{ textAlign: 'center' }}>
                {t('form.notAcceptOrder')}
              </Title>
            </>
          )}
        </FormCard>

        <AppFooter position="relative" />
      </Container>
    </>
  );
};

export default PublicOrderComponent;
